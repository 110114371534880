html {
  font-family: sans-serif;
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

img {
  vertical-align: middle;
  border-style: none;
}
img {
  max-width: 100%;
  height: auto;
}
.footer_image_wrap {
  display: inline-block;
  max-width: 100%;
  margin: 0 auto;
  vertical-align: middle;
}

.footer-left {
  padding-top: 30px;
  padding-bottom: 30px;
  float: left;
  z-index: 2;
  min-height: 1px;
}
.footer_image_wrap img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.footer-center {
  background-color: #2550aa;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 10px;
  padding-left: 1vw;
  display: flex;
}

@media (max-width: 1024px) {
  .footer-center {
    display: none;
  }

  .footer-right {
    display: none;
  }

  .footer-left {
    min-width: 100vw;
    max-width: 100%;
  }
  
  .footer-left > span {
    width: 100%;
  }
}

.footer-right {
  background-color: #2550aa;
  padding-top: 30px;
  padding-left: 20px;
  float: left;
  z-index: 2;
  min-height: 1px;
  padding-bottom: 30px !important;
}

.footer-right-icon {
  animation-timing-function: linear;
  animation-duration: 0.2s;
  list-style-type: none !important;
  margin-right: 5% !important;
  margin-left: 5% !important;
}

li.footer-right-icon {
  display: inline-block;
  margin-bottom: 8px;
}

.footer_text_inner {
  color: #ffffff !important;
}

.footer_text_inner p {
  color: #ffffff !important;
  font-family: 'inter', sans-serif;
  font-weight: 300;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 1rem;
  line-height: 2em;
  outline: 0;
  letter-spacing: 1px;
  line-height: 2em;
  padding-right: 20px !important;
  padding-bottom: 0px !important;
  padding-left: 3vw !important;
  margin-bottom: 0px !important;
}

.footer_text_inner a {
  color: #ffffff !important;
}

.footer_text_inner_left {
  width: 310px;
}
